<template>
  <div>
    <MyTable
      :columns="columns"
      :rows="rows"
      :date-range-enabled="true"
      :date-range-field="'date_added'"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'payment.reference_number'">
          <b-badge
            variant="primary"
            class="w-100 badge-padding"
          >
            {{ props.row.payment.reference_number }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'payment.transaction_id'">
          <b-badge
            variant="primary"
            class="w-100 badge-padding"
          >
            {{ props.row.payment.transaction_id ? props.row.payment.transaction_id : 'No entry found' }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'user'">
          <span class="d-block">{{ props.row.user.name }}</span>
          <span class="d-block">{{ props.row.user.email }}</span>
          <span class="d-block">{{ props.row.user.phone }}</span>
        </span>

        <span v-else-if="props.column.field === 'notes'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="handleViewDetails(props.row)"
              >
                <feather-icon
                  icon="ListIcon"
                  class="mr-50"
                />
                <span>View Details</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </MyTable>

    <b-modal
      ref="modal"
      hide-footer
      size="lg"
    >
      <div v-if="details">
        <b-form-group
          label="Evidence Provided:"
          class="mb-4"
        >
          <b-img
            :src="details.image"
            fluid
            style="width: 100%;"
          />
        </b-form-group>

        <b-form-group label="Notes Provided:">
          <h4>
            {{ details.notes }}
          </h4>
        </b-form-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import axios from '@axios'

export default {
  name: 'PaymentStatusPage',
  components: {
    MyTable,
  },
  data() {
    const columns = [
      {
        label: 'Transaction ID',
        field: 'payment.transaction_id',
        thClass: 'align-middle',
        tdClass: 'align-middle',
      },
      {
        label: 'Reference Number',
        field: 'payment.reference_number',
        thClass: 'align-middle',
        tdClass: 'align-middle',
      },
      {
        label: 'User Details',
        field: 'user',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Description',
        field: 'description',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'Date',
        field: 'date_added',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Notes',
        field: 'notes',
        thClass: 'align-middle',
        tdClass: 'align-middle',
        sortable: false,
      },
    ]

    return {
      columns,
      rows: [],
      isLoading: false,
      details: null,
    }
  },
  created() {
    this.fetchPaymentSessionLogs()
  },
  methods: {
    async fetchPaymentSessionLogs() {
      this.isLoading = true
      const response = await axios.get('/payment-session-logs')
      if (response.status !== 200) {
        this.isLoading = false
        this.$swal({
          title: 'Error!',
          text: 'Something went wrong in the request. Please refresh and try again.',
          icon: 'error',
          showCancelButton: false,
          customClass: {
            confirmButton: 'btn-danger',
          },
        })
        return
      }

      this.isLoading = false
      this.rows = response.data
    },
    handleViewDetails(details) {
      this.details = details
      this.$refs.modal.show()
    },
  },
}
</script>
